.mooncake {
  .intro-page {
    position: relative;

    img {
      width: 100%;
      display: block;
    }

    .order-btn {
      position: absolute;
      right: .1rem;
      top: .1rem;
      width: 0.6rem;
      height: 0.3rem;
    }

    .btn1, .btn2 {
      position: absolute;
    }

    .btn1 {
      background: url(./img/btn1.png) no-repeat;
      background-size: .28rem .198rem;
      width: .28rem;
      height: .198rem;
    }

    .btn2 {
      background: url(./img/btn2.png) no-repeat;
      background-size: .54rem .237rem;
      width: .54rem;
      height: .237rem;
    }

    .mask {
      position: absolute;
      bottom: 0;
      right: 0;
      left: 0;
      height: .5rem;
      z-index: 500;
    }

    .go-top-btn {
      display: flex;
      align-items: center;
      position: absolute;
      right: 0;
      top: 20.01rem;
      padding: .4rem 0.18rem .08rem 1rem;
      color: #fff;
      z-index: 1000;
      pointer-events: all;

      i {
        margin-right: .05rem;
        display: block;
        background: url('./img/top.png') no-repeat;
        background-size: .16rem .1rem;
        width: .16rem;
        height: .1rem;
      }
    }
  }
  .buy-page {
    background: rgb(253,253,253);

    .num-btn {

      button {
        height: .28rem;
        width: .28rem;
        padding: 0;
      }
      .add, .subtract {
        width: .24rem;
        height: .24rem;
        img {
          width: 100%;
          height: 100%;
        }
      }

      .amount {
        width: .35rem;
        text-align: center;
        margin-top: -.02rem;
        border: 0;
        // margin: 0 .1rem;
      }
    }

    .card-item-list {
      padding-top: .16rem;
      margin-bottom: 1.5rem;

      .item {
        overflow: hidden;
        margin: 0 0.16rem 0.16rem 0.16rem;
        background:rgba(255,255,255,1);
        box-shadow: 0 .01rem .15rem 0 rgba(239,239,239,1);
        border-radius: .12rem;

        .goods {
          width: 3.43rem;
          height: 1.7rem;
          img {
            width: 100%;
            height: 100%;
          }
        }

        .info {
          padding: .16rem;

          .title {
            font-size: .14rem;
            // font-weight:500;
            font-weight: bolder;
            color:rgba(14,14,14,1);
            line-height: .2rem;
            margin-bottom: .05rem;
          }
          .desc {
            color: #999999;
            font-size: .1rem;
            line-height: .14rem;
          }

          .bot {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: .08rem;
          }

          .price {
            font-size: .14rem;
            color: #4E4E50;
            font-weight: bolder;
          }

          .en {
            font-size: .13rem;
            color: #999999;
            font-weight: normal;
          }
        }
      }

      .item:first-child {
        box-shadow: 0 0rem .015rem 0 #EFEFEF;
      }

      .item.active {
        border: .01rem red solid;
      }
    }

    .footer {
      position: fixed;
      bottom: 0;
      width: 100%;
      // background: #fff;
      // .first {
      //   height: .4rem;
      //   line-height: .4rem;
      //   font-size: .12rem;
      //   justify-content: flex-end;
      //   border-top: .01rem solid #efefef;

      //   .package {
      //     position: relative;
      //     margin-top: -0.3rem;
      //     margin-left: .23rem;
      //     img {
      //       width: .53rem;
      //     }

      //     .count {
      //       position: absolute;
      //       display: block;
      //       background: #FFD163;
      //       border: .015rem solid #373737;
      //       border-radius: .21rem;
      //       width: .21rem;
      //       height: .21rem;
      //       line-height: .21rem;
      //       text-align: center;
      //       font-weight: bolder;
      //       right: -.06rem;
      //       top: 0rem;
      //     }
      //   }
      // }

      .settlement_box {
        // position: fixed;
        width: 3.41rem;
        // left: 0.16rem;
        // z-index: 4;
        // bottom: 0.05rem;
        padding: .06rem .16rem;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }

      .settlement_box .settlement {
        display: flex;
        width: 2.555rem;
        height: 0.43rem;
        font-size: 0.12rem;
        color: #0e0e0e;
        align-items: center;
        padding: 0 0.16rem 0 0.265rem;
        box-sizing: border-box;
        justify-content: space-between;
        background: rgba(231, 196, 135, 1);
        border-radius: 0.22rem 0.04rem 0.04rem 0.22rem;
      }

      .settlement .total {
        position: relative;
      }

      .settlement .amount_of_money {
        font-size: 0.15rem;
        text-align: right;
      }

      .settlement .amount_of_money span {
        font-size: 0.12rem;
      }

      .amount_of_money .actual_payment {
        font-size: 0.09rem;
        color: #6f6048;
      }

      .settlement .total span {
        color: white;
        border-radius: 50%;
        position: absolute;
        right: -0.06rem;
        width: 0.21rem;
        height: 0.21rem;
        line-height: 0.21rem;
        top: -0.25rem;
        text-align: center;
        font-size: 0.12rem;
        background-color: black;
      }

      .settlement .total img {
        width: 0.44rem;
        height: 0.52rem;
        margin-top: -0.25rem;
      }

      .settlement_box .check {
        color: #e7c487;
        font-size: 0.14rem;
        width: 0.815rem;
        height: 0.43rem;
        line-height: 0.43rem;
        text-align: center;
        background: rgba(14, 14, 14, 1);
        border-radius: 0.04rem 0.22rem 0.22rem 0.04rem;
      }

      .settlement_box .check.disabled {
        background:rgba(206,206,206,1);
        font-size: 0.11rem;
        color: #fff;
      }

      .settlement_box .check.disabled .sign {
        font-size: 0.08rem;
      }
    }
  }

  .amount-info {
    flex: 1;
    text-align: right;

    font-weight: bolder;

    span {
      font-size: .12rem;
    }

    .fee {
      font-weight: normal;
      color: #D8D8D8;
      margin-right: .12rem;
    }
    .total-label {
      font-weight:600;
      margin-right: .12rem;
    }
    .total-price {
      color: #0E0E0E;
      font-weight:600;
      // margin-right: .12rem;
    }

    .submit {
      background: #FFD163;
      border-radius: .12rem;
      width: .68rem;
      font-size: .12rem;
      // line-height: .24rem;
      height: .24rem;
      border: 0;
      margin: 0 .16rem;

    }
  }

  .item-list {

    .item {
      margin: 0 .16rem .16rem .16rem;
      background: #fff;
      border-radius: .1rem;
      padding: .12rem;
    }

    .goods {
      width: 1.05rem;
      height: 1.05rem;
      margin-right: .1rem;
      position: relative;
      border-radius: .1rem;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
      }

      .mask {
        position: absolute;
        left: 0; right: 0; top: 0; bottom: 0;
        color: #fff;
        display: flex;
        background: rgba(0, 0, 0, 0.6);
        justify-content: center;
        align-items: center;
        font-weight: bold;
        pointer-events: none;
      }

      .tag {
        position: absolute;
        font-size: .07rem;
        padding: .04rem .06rem;
        font-weight: bolder;
        color: white;
        background: rgb(237, 102, 87);
        border-radius: 0 0 .02rem 0;
      }
    }

    .info {
      flex: 1;
    }

    .over .info {
      opacity: .4;
    }

    h1 {
      font-size: .14rem;

      .price {
        float: right;
      }
    }

    .desc, .count {
      flex: 1;
      font-size: .1rem;
      color: #999999;
      // letter-spacing: 0.64px;
      text-align: justify;
    }

    .count {
      text-align: right;
    }

    .bot {
      height: .28rem;
      line-height: .28rem;

      .price {
        flex: 1;
        font-size: .14rem;
        color: #4E4E50;
        font-weight: bold;

        .en {
          font-size: .09rem;
          color: #BEBEBE;
        }
      }
    }
  }

  .form-area {
    box-shadow: 0 .02rem .15rem 0 #EFEFEF;
    background: #fff;
    border-radius: .1rem;
    margin: .16rem;
    padding: .07rem .16rem;
    font-size: .13rem;

    h1 {
      font-size: .14rem;
      color: #373737;
      font-weight: bolder;
      // margin-top: 0;
    }

    .form-item {
      display: flex;
      align-items: flex-start;
      // height: .35rem;
      padding: .05rem 0;
      line-height: .26rem;

      label {
        display: inline-block;
        width: 30%;
        opacity: 0.7;
      }

      .txt {
        margin-top: .03rem;
        flex: 1;
        font-size: .13rem;
        color: #0E0E0E;
        line-height:0.2rem;
      }

      input, .rn-selector {
        flex: 1;
        border: 0;
        padding: 0 .05rem;
        height: .26rem;
        line-height: .26rem;
        background: #fff;
      }

      input:focus {
        border: none;
        box-shadow: none;
      }

      input::-webkit-input-placeholder {
        color: rgb(228, 228, 228);
      }

      .rn-selector {
        position: relative;
        select {
          // position: absolute;
          width: 100%;
          height: 100%;
          z-index: -1;
        }

        .mask {
          padding-left: .05rem;
          pointer-events: none;
          display: flex;
          align-items: center;
          position: absolute;
          top: 0; left: 0;
          width: 100%;
          height: 100%;
          z-index: 1;
          background: #fff;

          span {
            flex: 1;
          }

          .arrow {
            background-image: url(./img/small-arrow.svg);
            background-repeat: no-repeat;
            background-size: 6.5px 11px;
            width: .12rem;
            height: .12rem;
          }

          // .arrow {
          //   // position: absolute;
          //   // top: .17rem;
          //   // right: .1rem;
          //   // width: 0;
          //   // height: 0;
          //   margin-top: .1rem;
          //   border-width: .06rem;
          //   border-style: solid;
          //   border-color:#000 transparent transparent transparent;
          // }
        }
      }

      .phone {
        flex: 1;
        position: relative;
        display: flex;
        align-items: center;

        .send-code-btn {
          background: #0e0e0e;
          position: absolute;
          right: 0;
          border: 0;
          color: #fff;
          font-size: .11rem;
          padding: .05rem .1rem;
          border-radius: .18rem;
        }
      }

      .radio-group {
        display: inline-block;
        flex: 1;
        padding-left: .05rem;

        a {
          display: inline-flex;
          align-items: center;
          min-width: .71rem;
          font-size: .13rem;
          color: #373737;
        }

        a:before {
          content: ' ';
          display: inline-block;
          background-image: url(img/radio-unchecked.svg);
          // border: .01rem solid #D8D8D8;
          // border-radius: .14rem;
          width: .14rem;
          height: .14rem;
          background-size: .14rem;
          margin-right: .05rem;
        }

        a.active:before {
          border: 0;
          background-image: url(img/radio-checked.svg);

        }
      }
    }

    .wallet-item {
      justify-content: space-between;
      label {
        width: auto;
      }
      .radio-group {
        a {
          min-width: auto;
          justify-content: flex-end;
        }
      }
    }
  }

  .form-page {
    .item-list {
      background: #fff;
      border-radius: .1rem;
      margin: .16rem;
      box-shadow: 0 .02rem .15rem 0 #EFEFEF;

      .item {
        margin: 0;
      }
    }

    .amount-info {
      padding: 0.09rem .12rem .12rem .12rem;
      text-align: right;

      .fee {
        font-size: .12rem;
        font-weight: normal;
        color: #BEBEBE;
        margin-right: .12rem;
      }
      .total-label {
        font-weight: bold;
        margin-right: .04rem;
        font-size: .12rem;
      }
      .total-price {
        color: #0E0E0E;
        font-size: .12rem;
        // margin-right: .12rem;
      }
    }

    .label1 {
      justify-content: space-between;
      label {
        width: auto;
      }
    }
  }

  .orders-page {
    .order-item {
      margin: .16rem;
      padding: .2rem .16rem;
      background:rgba(255,255,255,1);
      box-shadow: 0 .02rem .3rem 0 rgba(239,239,239,1);
      border-radius: .12rem;

      .title {
        font-size: .14rem;
        color: #373737;
        font-weight: bolder;
        margin-bottom: .2rem;
        // margin-top: 0;
        .order-status {
          float: right;
        }

        .status-1, .status-2, .status-4 {
          color: #E7C487;
        }

        // .status-2, .status-4 {
        //   color: #FFD163;
        // }

        .status-3, .status-5, .status-6, .status-7 {
          color: #999999;
        }
      }

      .item-list .item {
        margin: 0;
        padding: 0;
        padding-bottom: .09rem;

        h1 {
          margin-top: 0;
        }

        .goods {
          width: .86rem;
          height: .86rem;
        }
      }

      .info-area {

        .item {
          margin-top: .2rem;
          display: flex;
        }

        label {
          font-size: .13rem;
          color: #373737;
          width: 30%;
        }

        span {
          font-size: .13rem;
          color: #373737;
          flex: 1;
        }
      }
      .amount-info {
        margin: .1rem 0;
      }

      .operation-area {
        display: flex;
        justify-content: flex-end;
        margin-top: .2rem;
        .contact, .submit {
          border-radius: .27rem;
          border: .01rem solid rgba(211,211,211,1);
          // height: .27rem;
          line-height: .27rem;
          font-size: .12rem;
          color: #373737;
          padding: 0 .2rem;
          margin-left: .08rem;
          background: #fff;
        }
        .submit {
          background: rgba(14,14,14,1);
          border-color: rgba(14,14,14,1);
          color: #fff;
          margin-right: 0;
        }

      }
    }

    .page-signs {
      // margin-top: .16rem;
      display: flex;
      height: .3rem;
      justify-content: center;
      align-items: center;

      span {
        display: block;
        background: #e0e0e0;
        width: .16rem;
        height: .03rem;
        margin: 0 .02rem;
        border-radius: .02rem;

        &.active {
          background: #F3C16C;
        }
      }
    }

    .error, .loading {
      padding-top: 1rem;
      text-align: center;
    }
  }

  .submit-btn {
    font-size: .16rem;
    color: #FFFFFF;
    display: block;
    margin: 0 .16rem .16rem .16rem;
    padding: .1rem;
    border-radius: .22rem;
    // background: #FFD163;
    background: rgba(14,14,14,1);
    text-align: center;
    font-weight: bolder;
  }

  .submit-btn.disabled {
    color: rgba(255, 255, 255, .5);
  }

  .switch-btn {
    display: inline-block;
    position: relative;
    width: .29rem;
    height: .15rem;

    .bar {
      position: absolute;
      height: .1rem;
      border-radius: .1rem;
      background: #D8D8D8;
      top: 50%;
      margin-top: -.05rem;
      left: 0;
      width: 100%;
      opacity: 0.26;
    }

    .btn {
      position: absolute;
      background: #D8D8D8;
      width: .15rem;
      height: .15rem;
      border-radius: .15rem;
      right: auto;
      left: 0;
      top: 0;
    }

    &.active {
      .bar {
        background: #FFD163;
      }

      .btn {
        background: #FFD163;
        left: auto;
        right: 0;
      }
    }
  }

  .mooncake-success-page {

    .sign {
      img {
        width: .66rem;
        height: .66rem;
        margin-bottom: .16rem;
      }

      span {
        font-size: .16rem;
      }
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: .3rem;
    }

  }

  .footer-nav {
    height: .49rem;
    background: #fff;
    display: flex;
    border-top: .01rem solid #efefef;

    a {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;

      color: #D8D8D8;

      i {
        background-size: .25rem;
        width: .25rem;
        height: .25rem;
        margin-right: .05rem;
      }

      span {
        font-size: .09rem;
      }
    }

    i.commodity {
      background-image: url(img/commodity.svg);
    }

    i.orderform {
      background-image: url(img/orderform.svg);
    }

    a.active {
      color: #0E0E0E;

      span.en { opacity: .5; }
      i.commodity {
        background-image: url(img/commodity-active.svg);
      }

      i.orderform {
        background-image: url(img/orderform-active.svg);
      }
    }
  }

  .search-form {
    .bg {
      position: fixed;
      top: 0;
      width: 100%;
      z-index: -1;
    }

    .form-area {

      margin-top: 1.01rem;
      margin-bottom: .2rem;
      position: relative;

      .decorate-person {
        position: absolute;
        width: .9rem;
        top: -.46rem;
        right: .1rem;
      }


    }
    .footer-nav {
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
    }
  }

  .link-creator {
    .bg {
      height: 1.26rem;
      overflow: hidden;
      img {
        width: 100%;
      }
    }

    .form-area {
      .btn {
        color: #000;
        width: .875rem;
        text-align: center;
        background: #FFD163;
        border-radius: .12rem;
        font-size: .12rem;
        padding: 0 .03rem;
      }
    }

    .result-area {
      margin-top: .1rem;
    }
  }

  .result-area {
    margin-top: .1rem;
    padding: 0.2rem;

    .info {
      font-size: .13rem;
      color: #666666;
      text-align: center;
      margin: .15rem 0;
    }

    .successs-info {
      font-size: .13rem;
      color: #373737;
      line-height: .24rem;
      text-align: center;

      .name {
        color: #FFD163;
        font-weight: bold;
      }
    }
  }

  .custorm-info {
    position: fixed;
    text-align: center;

    color: #BEBEBE;
    width: 100%;
    bottom: .62rem;
    left: 0;

    div {
      font-size: .11rem;
    }

    .with-line {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .with-line:before, .with-line:after {
      content: ' ';
      display: inline-block;
      background: url(img/line.svg) no-repeat center;
      width: .3rem;
      height: .1rem;
      margin: 0 .08rem;
    }

  }

  .total-price {
    font-weight: 900;
  }
}

.title-img {
  width: 0;
  height: 0;
}

.goods-mask {
  position: absolute;
  // display: flex;
  // justify-content: center;
  // align-items: center;
  overflow: auto;
  min-height: 100%;
  top: 0;
  // bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, .8);
  z-index: 100;
  text-align: center;

  img {
    width: 100%;
    padding: 0rem 0;
  }

  .close {
    // border: #fff .02rem solid;
    background: rgba(0, 0, 0, .5);
    border-radius: .3rem;
    color: #fff;
    position: fixed;
    bottom: .4rem;
    font-size: .25rem;
    left: 50%;
    width: .3rem;
    height: .3rem;
    line-height: .3rem;
    margin-left: -.15rem;
    display: block;
    padding: .1rem;
  }
}

