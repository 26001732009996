.icon {
  width: 0.3rem;
  height: 0.3rem;
  background-size: 0.3rem;

  &.burn {
    background-image: url(./icons/burn.svg);
  }
  &.cake {
    background-image: url('./icons/cake.svg');
  }
  &.vip {
    background-image: url(./icons/vip.svg);
  }
  &.svip {
    background-image: url(./icons/svip.svg);
  }
  &.card {
    background-image: url(./icons/card.svg);
  }
  &.discount {
    background-image: url('./icons/discount.svg');
  }
  &.alipay {
    background-image: url('./icons/alipay.svg');
  }
  &.wechat {
    background-image: url('./icons/wechat.svg');
  }
  &.checked {
    background-image: url('./icons/checked.svg');
  }
  &.unchecked {
    background-image: url('./icons/unchecked.svg');
  }
}
