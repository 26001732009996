@import './icons.less';

body {
  margin: 0;
  padding: 0;
  font-size: 0.14rem;
  background: #fafafa;
}

p,span,a,font,div,button,input,select {
  font-size: 0.14rem;
  text-decoration: none;
}

.flex-view {
  display: flex;

  &.col {
    flex-direction: column;
  }

  &.row {
    flex-direction: row;
  }

  &.center {
    justify-content: center;
    align-items: center;
  }
}

.fixed-mask {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;

  .loading {
    width: 1.2rem;
    height: 1.2rem;
    background: rgba(0, 0, 0, 0.8);
    border-radius: .1rem;
  }
}

.fixed-alert {
  position: fixed;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: .4rem;
  line-height: .4rem;
  top: 50%;
  left: 0;
  margin-top: -.25rem;
  width: 100%;


  .prompt {
    height: 100%;
    padding: 0 .3rem;
    background: rgba(0, 0, 0, 0.8);
    color: #fff;
    border-radius: .3rem;
  }
}

.page-frame {
  border: 0.01rem solid #020202;
  margin: 0.17rem;
}

.submit-btn {
  display: block;
  background: #000;
  color: #fff;
  padding: .12rem;
  border: 0;
}

.wbox {
  background: #FFFFFF;
  box-shadow: 0 1px 6px 0 rgba(218,220,224,0.10);
  border-radius: .06rem;
}

// 会员简介
.intro {
  margin-bottom: 0.7rem;
  h1 {
    font-size: 0.27rem;
    text-align: center;
    border-bottom: 0.02rem solid #000;
    line-height: 0.5rem;
    margin-left: 0.24rem;
    margin-right: 0.24rem;
  }

  .subtitle {
    text-align: center;
    font-size: 0.13rem;
    letter-spacing: 0.13rem;
    margin-bottom: 0.2rem;
  }

  .intro-item {
    .wbox();
    margin: 0 .14rem .08rem .14rem;
    padding: .3rem;
    line-height: .16rem;

    .icon {
      margin-right: .1rem;
    }

    .txt-area {
      flex: 1;
    }

    .title {
      color: #000000;
    }

    .desc {
      font-size: .11rem;
      color: #999999;
    }
  }

  .subscrib-btn {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background: #000;
    color: #fff;
    padding: 0.13rem;
    text-align: center;
    .txt {
      font-size: .18rem;
    }
    .price {
      font-size: .12rem;
      margin-left: .1rem;
    }
  }
}

// 注册表单页面
.register-form, .login-form {
  h1 {
    font-size: 0.2rem;
    text-align: center;
  }

  .inner-form {
    .wbox();
    padding: .2rem;
    margin: .16rem;
  }

  .form-item {
    margin: .05rem 0;
  }

  label {
    font-size: .14rem;
    color: #ABABAB;
    line-height: .34rem;
  }

  input[type=text], .rn-selector, .date-picker{
    display: block;
    flex: 1;
    border: 0.01rem solid #000000;
    height: .4rem;
    line-height: .4rem;
    border-radius: 0;
    background: #fff;
    font-weight: bold;
    font-size: .14rem;
    // padding: 0;
    margin: 0;
  }

  .text-input {
    flex: 1;
    border: 0.01rem solid #000000;
    display: flex;
    input[type=text] {
      border-width: 0;
      display: block;
      flex: 1;
    }
  }

  .rn-selector, .date-picker {
    overflow: hidden;
    position: relative;

    .mask {
      // padding-left: 0.15rem;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: #FFFFFF;
      pointer-events: none;

      span {
        margin-left: .1rem;
      }

      .arrow {
        position: absolute;
        top: .17rem;
        right: .1rem;
        width: 0;
        height: 0;
        border-width: .06rem;
        border-style: solid;
        border-color:#000 transparent transparent transparent;
      }
    }

    select, input {
      // visibility: hidden;
      // position: absolute;
      display: block;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      width: 100%;
      height: 100%;
      // width: 0; height: 0;
      z-index: -1;
      color: #fff;
      // border: 0;
    }
  }

  .date-picker {
    // display: flex;
    border-width: 0;

    input {
      // flex: 1;
      width: 2.5rem;
    }
    // .birthday-group {
    .mask {
      padding: 0;
    }

    .selector {
      flex: 1;
      position: relative;
      border: 0.01rem solid #000000;
      margin-right: .07rem;
      padding-left: 0.1rem;
    }

    .selector:last-child {
      margin-right: 0;
    }
  }

  input {
    padding: 0 .1rem;
  }

  .phone {
    position: relative;
    width: 100%;
    border: 0.01rem solid #000000;

    input[type=text] {
      border: 0;
      width: 60%;
      // border-right-width: 0;
    }

    .send-code-btn {
      display: block;
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      width: 1rem;
      color: #F3C16C;
      border: 0;
      border-width: .01rem .01rem .01rem 0;
      background: #fff;
      font-size: .13rem;
    }
  }

  .submit-btn {
    margin-top: .15rem;
  }
}

// 支付页面
.pay-channel {
  position: fixed;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  display: flex;

  .page-frame {
    flex: 1;
    padding: .2rem;
  }

  .item {
    .wbox();
    margin-bottom: .14rem;
  }

  .amount-area {
    h1 {
      font-size: .2rem;
      margin: .32rem 0 .16rem 0;
    }
    .amount-info-area {
      align-items: flex-end;
      border-bottom: .02rem solid #000;
      padding: 0 .2rem;
    }

    .amount {
      font-size: .5rem;
      font-weight: bold;
    }
    .unit {
      margin-left: .1rem;
      line-height: .45rem;
    }

    .extra-info {
      margin-top: .1rem;
      margin-bottom: .25rem;
      font-size: .11rem;
      color: #ABABAB;
    }
  }

  .channel {
    padding: .16rem .2rem;
    align-items: center;
    font-size: .13rem;
    .title {
      margin-left: .15rem;
      flex: 1;
    }
  }
}

.success-page {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;

  .page-frame {
    flex: 1;
    padding: .16rem;
    background: #FFFFFF;
    // align-items: center;
  }

  h1 {
    font-size: 0.20rem;
    text-align: center;
    line-height: 0.5rem;
    margin-left: 0.24rem;
    margin-right: 0.24rem;
  }

  .icon {
    margin-top: .3rem;
  }

  .info1 {
    margin-top: .18rem;
    font-size: .2rem;
    font-weight: bold;
    color: #000000;
  }

  .info2 {
    font-size: .11rem;
    color: #ABABAB;
    text-align: center;
  }

  .submit-btn {
    margin-top: .48rem;
    background: #fff;
    color: #000;
    border: #000 .01rem solid;
    border-radius: .03rem;
  }

  .qrcode {
    margin-bottom: .2rem;
    img {
      width: 1.7rem;
      height: 1.7rem;
    }
  }
}

.alipay-prompt {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  color: #fff;
  align-items: center;
  padding-top: .9rem;

  .info {
    width: 3rem;
    line-height: 0.35rem;
    font-size: .2rem;
    position: relative;
  }

  .arrow {
    position: absolute;
    top: 0.2rem;
    right: 0.2rem;
    background-image: url(img/arrow.png);
    width: 0.76rem;
    height: 0.79rem;
    background-size: 0.76rem;
  }

  button {
    width: 1.84rem;
    height: .44rem;
    /* line-height: .44rem; */
    background: #fff;
    margin-top: .3rem;
    font-weight: bold;
    border: 0;
  }
}

.login-form {
  position: fixed;
  left: 0; bottom: 0;
  right: 0; top: 0;
  display: flex;

  .page-frame {
    flex: 1;
  }

}
